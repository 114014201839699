export function getBaseUrl() {
  return process.env.REACT_APP_API_HOSTNAME;
}

export function getLoginUrl() {
  return `${getBaseUrl()}/auth/login/`;
}

export function getRegistrationUrl() {
  return `${getBaseUrl()}/auth/register/`;
}

export function getGqlUrl() {
  return `${getBaseUrl()}/graph_api/graphql/`;
}

export function getUploadImageUrl() {
  return `${getBaseUrl()}/main/flashcards/upload-image/`;
}

export function getGtagId() {
  return process.env.REACT_APP_GA_TRACKING_ID;
}
