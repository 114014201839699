import { uniqueId } from 'lodash';
import React, { useState, createContext, ReactNode, useContext } from 'react';

const MESSAGE_DURATION_MILLISECONDS = 10 * 1000;

type AlertLevel = 'error' | 'warning' | 'info' | 'success';
export interface Message {
  id: string;
  display: ReactNode;
  level?: AlertLevel;
}

interface AlertContextType {
  alerts: Array<Message>;
  addAlert: (display: ReactNode, level?: AlertLevel) => void;
  dismissAlert: (id: string) => void;
}

const AlertContext = createContext<AlertContextType>({} as AlertContextType);

function useAlertState() {
  const [alerts, setAlerts] = useState<Array<Message>>([]);
  const addAlert = (display: ReactNode, level?: AlertLevel) => {
    const messageId = uniqueId() + '';
    const message = { display, id: messageId, level };

    setAlerts([...alerts, message]);
    setTimeout(() => {
      setAlerts(liveAlerts => liveAlerts.filter(({ id }) => id !== messageId));
    }, MESSAGE_DURATION_MILLISECONDS);
  };

  const dismissAlert = (messageId: string) => {
    setAlerts(liveAlerts => liveAlerts.filter(({ id }) => id !== messageId));
  };

  return {
    alerts,
    addAlert,
    dismissAlert,
  };
}

// less priviledged hook to add messages
export function useAddAlert() {
  const { addAlert } = useContext(AlertContext);
  return addAlert;
}

// less priviledged hook to add messages
export function useAlertList() {
  const { alerts } = useContext(AlertContext);
  return alerts;
}

// less priviledged hook to add messages
export function useDismissAlert() {
  const { dismissAlert } = useContext(AlertContext);
  return dismissAlert;
}

export function AlertProvider({ children }: { children: ReactNode }) {
  const alertState = useAlertState();
  return (
    <AlertContext.Provider value={alertState}>{children}</AlertContext.Provider>
  );
}
