import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from 'apollo-link-error';

import { emitLogoutEvent, getAuthHeaders } from 'platform';
import { getGqlUrl } from 'platform/config';

const graphqlUri = getGqlUrl();
// Instantiate required constructor fields
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchNotesConnection: relayStylePagination([
          'deckId',
          'query',
          'sortKey',
          'deckId',
        ]),
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: graphqlUri,
});

const authLink = setContext((_, { headers }) => {
  const authHeaders = getAuthHeaders();
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const logoutAfterWareLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode == 401) {
    emitLogoutEvent();
  }
});

const client = new ApolloClient({
  cache: cache,
  link: authLink.concat(logoutAfterWareLink).concat(httpLink),
});

export default client;
