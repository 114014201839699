import { useContext } from 'react';

import { AuthContext } from './AuthContext';

export function useUser() {
  const context = useContext(AuthContext);
  return {
    loading: context.loading,
    user: context.user,
  };
}
