import 'bootstrap/dist/css/bootstrap.min.css';
import 'highlight.js/styles/github.css';
import 'base.scss';
import './App.css';

import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import React, { lazy, useMemo, Suspense, Fragment } from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  Router,
} from 'react-router-dom';

import AnalyticsSynchronizer from 'components/AnalyticsSynchronizer';
import PrivateRoute from 'components/PrivateRoute';
import client from 'graphql/client';
import { useUser, AuthContext, useAuthState, getHistory } from 'platform';
import { AlertProvider } from 'platform/alerts';
import { HomeRoute, LandingRoute } from 'routes';
import {
  PrismThemeSynchronizer,
  MermaidThemeSynchronizer,
} from 'utilities/markdown';

import { normalTheme, darkTheme } from './theme';

const LoggedOutComponent = lazy(() => import('logged_out/Main'));
const LoggedInComponent = lazy(() => import('logged_in/Main/'));

function Root() {
  const { user } = useUser();

  if (user) {
    return <Redirect to={HomeRoute.generate()} />;
  }
  return <Redirect to={LandingRoute.generate()} />;
}

function App() {
  const authState = useAuthState();
  const isLoading = authState.loading;
  const history = getHistory();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const prefersDarkMode = true;

  const theme = useMemo(() => (prefersDarkMode ? darkTheme : normalTheme), [
    prefersDarkMode,
  ]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <AlertProvider>
      <AuthContext.Provider value={{ ...authState }}>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={theme}>
            <PrismThemeSynchronizer />
            <MermaidThemeSynchronizer />
            <Router history={history}>
              <AnalyticsSynchronizer />
              <Suspense fallback={<Fragment />}>
                <Switch>
                  <Route path="/" exact>
                    <Root />
                  </Route>
                  <PrivateRoute path="/app/">
                    <LoggedInComponent />
                  </PrivateRoute>
                  <Route>
                    <LoggedOutComponent />
                  </Route>
                </Switch>
              </Suspense>
            </Router>
          </MuiThemeProvider>
        </ApolloProvider>
      </AuthContext.Provider>
    </AlertProvider>
  );
}

export default App;
