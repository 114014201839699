import { generatePath } from 'react-router-dom';

export interface Route {
  route: string;
  generate(...args: Array<string | number>): string;
}

export const LandingRoute = {
  route: '/product/',
  generate() {
    return generatePath(this.route);
  },
};

export const LoginRoute = {
  route: '/auth/login',
  generate() {
    return generatePath(this.route, {});
  },
};
export const SignupRoute = {
  route: '/signup/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const EmailVerificationRoute = {
  route: '/auth/verify-email/:key/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const ForgotPasswordRoute = {
  route: '/auth/forgot-password/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const RecoveryPasswordResetRoute = {
  route: '/auth/password-recovery-reset/:key/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const SingleDeckStudyRoute = {
  route: '/app/deck/:deckId/study/',
  generate(deckId: number) {
    return generatePath(this.route, { deckId });
  },
};

export const StudyAllRoute = {
  route: '/app/study-all/',
  generate() {
    return generatePath(this.route);
  },
};

export const PlaylistStudyRoute = {
  route: '/app/playlists/:playlistId/study/',
  generate(playlistId: number) {
    return generatePath(this.route, { playlistId });
  },
};

export const DeckDetailRoute = {
  route: '/app/deck/:deckId/',
  generate(deckId: number) {
    return generatePath(this.route, { deckId });
  },
};

export const MoveDeckToTeamRoute = {
  route: '/app/deck/:deckId/move-to-team/',
  generate(deckId: number | string) {
    return generatePath(this.route, { deckId });
  },
};

export const CreateNoteRoute = {
  route: '/app/deck/:deckId/notes/create/',
  generate(deckId: number) {
    return generatePath(this.route, { deckId });
  },
};

export const DecksRoute = {
  route: '/app/decks/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const HomeRoute = {
  route: '/app/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const PlaylistsRoute = {
  route: '/app/playlists/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const EditNoteRoute = {
  route: '/app/notes/:noteId/edit/',
  generate(noteId: number) {
    return generatePath(this.route, { noteId });
  },
};

export const CardBrowserRoute = {
  route: '/app/browse-notes/:deckId?',
  generate(deckId?: number) {
    return generatePath(this.route, { deckId });
  },
};

export const CreateDeckRoute = {
  route: '/app/decks/create/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const CreatePlaylistRoute = {
  route: '/app/playlists/create/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const PlaylistDetailRoute = {
  route: '/app/playlists/:playlistId/',
  generate(playlistId: number) {
    return generatePath(this.route, { playlistId });
  },
};
export const EditPlaylistRoute = {
  route: '/app/playlists/:playlistId/edit/',
  generate(playlistId: number) {
    return generatePath(this.route, { playlistId });
  },
};

export const OwnedDecksRoute = {
  route: '/app/owned-decks/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const PublicDecksRoute = {
  route: '/app/public-decks/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const ResetPasswordRoute = {
  route: '/app/account/reset-password/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const AccountRoute = {
  route: '/app/account/',
  generate() {
    return generatePath(this.route, {});
  },
};

export const TeamsIndexRoute = {
  route: '/app/teams/',
  generate() {
    return generatePath(this.route, {});
  },
};
export const TeamDetailRoute = {
  route: '/app/teams/:id',
  generate(id: number | string) {
    return generatePath(this.route, { id });
  },
};
export const CreateTeamRoute = {
  route: '/app/teams/create',
  generate() {
    return generatePath(this.route, {});
  },
};

export const ModifyTeamRolesRoute = {
  route: '/app/teams/:id/modify-roles',
  generate(id: number | string) {
    return generatePath(this.route, { id });
  },
};
