import { useEffect, useState } from 'react';
import { Route, Router, useLocation } from 'react-router-dom';

import { getGtagId } from 'platform/config';

export default function AnalyticsSynchronizer() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (typeof gtag === 'function') {
      setInitialized(true);
    }
  }, []);
  useEffect(() => {
    if (!initialized) {
      return;
    }
    const gTagId = getGtagId();
    if (typeof gtag === 'function') {
      gtag('config', gTagId, {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }, [location, initialized]);
  return null;
}
