import { EventEmitter } from 'events';

const LOGOUT_EVT_NAME = 'logout';

const eventEmitter = new EventEmitter();

export const addLogoutEventListener = (callback: () => void) => {
  eventEmitter.addListener(LOGOUT_EVT_NAME, callback);
};

export const emitLogoutEvent = () => {
  eventEmitter.emit(LOGOUT_EVT_NAME);
};
