import { createContext, useState, useEffect, useCallback } from 'react';

import { getHistory } from 'platform';
import { getLoginUrl } from 'platform/config';

import { LandingRoute } from 'routes';

import { addLogoutEventListener } from './EventEmitter';
import { User } from './UserModel';

export interface AuthContextType {
  loading: boolean;
  user?: User;
  login: (username: string, password: string) => Promise<true | string>;
  logout: () => Promise<void>;
}
export const AuthContext = createContext<Partial<AuthContextType>>({
  loading: true,
});

const getInitialState = () => {
  const rawUser = window.localStorage.getItem('srs_user');
  let user: User | undefined;
  if (rawUser) {
    user = JSON.parse(rawUser);
  }

  return {
    user,
    loading: false,
  };
};

export function useAuthState() {
  const [authState, setAuthState] = useState(getInitialState());

  const login = useCallback(
    async (username: string, password: string) => {
      try {
        const response = await fetch(getLoginUrl(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        const { key } = data;
        if (!key) {
          throw 'Login failed';
        }
        const user = { username };
        localStorage.setItem('srs_user', JSON.stringify(user));
        localStorage.setItem('auth_token', key);
        setAuthState(state => ({ ...state, user }));
        return true;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    [setAuthState]
  );

  const logout = useCallback(async () => {
    // const csrfToken = Cookies.get("csrftoken");
    localStorage.removeItem('srs_user');
    localStorage.removeItem('auth_token');
    setAuthState(({ user, ...newState }) => ({
      user: undefined,
      ...newState,
    }));
    const history = getHistory();
    history.push(LandingRoute.generate());
  }, [setAuthState]);

  useEffect(() => {
    addLogoutEventListener(() => {
      logout();
    });
  }, [logout]);

  return {
    user: authState.user,
    loading: authState.loading,
    login,
    logout,
  };
}
