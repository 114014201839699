import { useContext } from 'react';

import { AuthContext } from './AuthContext';

/*

    just an explicit wrapper around context.login

*/
export function useLogin() {
  const context = useContext(AuthContext);
  return context.login;
}
