import { useTheme } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import katex from 'katex';
import marked from 'marked';
import mermaid from 'mermaid';
import prism from 'prismjs';
import React, { useEffect } from 'react';
import 'prismjs/components/prism-python';

/* eslint import/no-webpack-loader-syntax: off */
// eslint-disable-next-line import/no-unresolved
import darkStyles from '!css-loader!prismjs/themes/prism-okaidia.css';
// eslint-disable-next-line import/no-unresolved
import lightStyles from '!css-loader!prismjs/themes/prism-solarizedlight.css';

const katexOptions = {
  displayMode: false,
  output: 'mathml',
  // throwOnError: false,
};
function renderKatex(katexString: string, displayMode: boolean): string {
  return katex.renderToString(katexString, { ...katexOptions, displayMode });
}

function initializeMermaid(mode: 'default' | 'dark') {
  mermaid.initialize({
    theme: mode,
    // fontFamily: '"Lucida Console", Monaco, monospace',
    startOnLoad: false,
    // securityLevel: 'loose',
  });
}

function renderMermaid(mermaidString: string): string {
  const someNumber = _.uniqueId();
  const htmlId = `mermaid-container-${someNumber}`;
  const svgId = `mermaid-svg-${someNumber}`;
  setTimeout(() => {
    if (!document.querySelector(`#${htmlId}`)) {
      return;
    }
    mermaid.render(svgId, mermaidString, (svg: string) => {
      const el = document.querySelector(`#${htmlId}`);
      if (el) {
        el.innerHTML = svg;
      }
    });
  }, 0);
  return `<div id="${htmlId}"></div>`;
}

marked.setOptions({
  highlight: function (code, lang) {
    if (lang === 'mermaid') {
      return renderMermaid(code);
    }
    if (lang === 'katex') {
      return renderKatex(code, true);
    }
    if (lang === 'katex-inline') {
      return renderKatex(code, false);
    }
    if (!prism.languages[lang]) {
      return code;
    }
    return prism.highlight(code, prism.languages[lang], lang);
  },
});

export function renderMarkdown(source: string) {
  const html = marked(source);
  const sanitizedHtml = DOMPurify.sanitize(html, {
    FORBID_TAGS: ['script', 'style'],
  });
  return sanitizedHtml;
}

export function PrismThemeSynchronizer() {
  const theme = useTheme();
  let css = '';
  if (theme.palette.type === 'dark') {
    css = darkStyles;
  } else {
    css = lightStyles;
  }
  return <style dangerouslySetInnerHTML={{ __html: css }} />;
}

export function MermaidThemeSynchronizer() {
  const theme = useTheme();
  useEffect(() => {
    if (theme.palette.type === 'dark') {
      initializeMermaid('dark');
    } else {
      initializeMermaid('default');
    }
  }, [theme.palette.type]);
  return <></>;
}

export function Markdown({ source }: { source: string }) {
  const rendered = renderMarkdown(source);
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: rendered }} />
    </>
  );
}

export function extractTextFromMarkdownSource(source: string) {
  const rendered = renderMarkdown(source);
  const span = document.createElement('span');
  span.innerHTML = rendered;
  return span.textContent || span.innerText;
}
