import { useContext } from 'react';

import { AuthContext } from './AuthContext';

/*

    just an explicit wrapper around context.login

*/
export function useLogout() {
  const context = useContext(AuthContext);
  return [context.logout];
}
