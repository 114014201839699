import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useUser } from 'platform';

import { LoginRoute } from 'routes';

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props;
  const { user } = useUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LoginRoute.generate(),
              state: { next: { location } },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
