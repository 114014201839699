import { createMuiTheme } from '@material-ui/core';

const commonTheme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize: '1.2rem',
        },
      },
    },
  },
};

export const normalTheme = createMuiTheme({
  ...commonTheme,
  palette: {
    type: 'light',
    // type: 'dark',
  },
});

export const darkTheme = createMuiTheme({
  ...commonTheme,
  palette: {
    type: 'dark',
  },
});
