import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';

import { useAlertList, useDismissAlert } from './AlertContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

export function AlertBar() {
  const classes = useStyles();
  const alerts = useAlertList();
  const dismissAlert = useDismissAlert();
  console.log('\n alerts');
  console.log(alerts);

  return (
    <div className={classes.root}>
      {alerts.map(a => (
        <Alert onClose={() => dismissAlert(a.id)} key={a.id} severity={a.level}>
          {a.display}
        </Alert>
      ))}
    </div>
  );
}
